import anime from "animejs/lib/anime.es.js";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const $intro = document.querySelector(".intro");

function introAnimation() {
  const headings = document.querySelectorAll("[data-intro-heading]");
  const letters = "ab2cd5efgh4ijklmnopq1rst78uvwxyz";
  const allLetters = $intro.querySelectorAll("[data-char]");

  headings.forEach((heading) => {
    const name = heading.querySelector(".intro__service-item");
    if (name == null) return;
    let interval = null;

    heading.onmouseenter = (event) => {
      if (heading.dataset.isAnimating === "true") {
        return;
      }

      heading.dataset.isAnimating = "true";
      let iteration = 0;

      clearInterval(interval);

      interval = setInterval(() => {
        name.innerText = name.innerText
          .split("")
          .map((letter, index) => {
            if (index < iteration) {
              return name.dataset.value[index];
            }

            return letters[Math.floor(Math.random() * 26)];
          })
          .join("");

        if (iteration >= name.dataset.value.length) {
          clearInterval(interval);
          heading.dataset.isAnimating = "false";
        }

        iteration += 1 / 3;
      }, 30);
    };
  });

  gsap.matchMedia().add("(max-width: 767px)", () => {
    const timeline = gsap.timeline({
      defaults: { duration: 0.6, ease: "power1.out" },
      onComplete: function () {
        const event = new CustomEvent("introEnd");
        document.dispatchEvent(event);
      },
    });

    timeline
      .fromTo(".intro__image-frame", { opacity: 1, yPercent: 45 }, { opacity: 1, yPercent: 0, duration: 0.8, ease: "elastic.out(1.5, 1)" })
      .fromTo(allLetters, { yPercent: -102 }, { yPercent: 0, duration: 0.5, ease: "elastic.out(1, 0.3)", stagger: 0.06 })
      .fromTo(".header__nav-frame", { scale: 0 }, { scale: 1, duration: 0.3 }, "-=1.2")
      .fromTo([".header__logo-icon", ".intro__lead-in"], { scale: 0 }, { scale: 1, duration: 0.4 })
      .fromTo(".intro__hint", { scale: 0 }, { scale: 1, ease: "elastic.out(1, 0.3)" })
      .fromTo(".intro__hint span", { scale: 0 }, { scale: 1, ease: "elastic.out(1, 0.3)", duration: 0.4 }, "-=0.4");
  });

  gsap.matchMedia().add("(min-width: 768px)", () => {
    const timeline = gsap.timeline({
      defaults: { duration: 0.6, ease: "power1.out" },
      onComplete: function () {
        const event = new CustomEvent("introEnd");
        document.dispatchEvent(event);
      },
    });

    timeline
      .fromTo(".intro__image-frame", { opacity: 1, yPercent: 45 }, { opacity: 1, yPercent: 0, duration: 0.8, ease: "elastic.out(1.5, 1)" })
      .fromTo(allLetters, { yPercent: -102 }, { yPercent: 0, duration: 0.5, ease: "elastic.out(1, 0.3)", stagger: 0.06 })
      .fromTo(".header__nav-frame", { y: -100 }, { y: 0, duration: 0.4 }, "-=1.2")
      .fromTo([".header__logo-icon", ".intro__lead-in"], { scale: 0 }, { scale: 1, duration: 0.4 })
      .fromTo(".intro__hint", { scale: 0 }, { scale: 1, ease: "elastic.out(1, 0.3)" });
  });
}

const stageAnimation = (el) => {
  const allLetters = el.querySelectorAll("[data-char]");

  const timeline = anime.timeline({
    duration: 750,
  });

  timeline
    .add({
      targets: ".stage__overlay",
      duration: 800,
      easing: "easeOutQuad",
      scale: [2, 1],
    })
    .add({
      targets: allLetters,
      translateY: ["-105%", "0"],
      duration: 400,
      delay: (el, i) => 50 * i,
    })
    .add(
      {
        targets: ".stage__hint",
        scale: [0, 1],
      },
      "-=250"
    )
    .add(
      {
        targets: ".stage__hint span",
        scale: [0, 1],
      },
      "-=250"
    );
};

const $stage = document.querySelector(".stage");

document.addEventListener("DOMContentLoaded", () => {
  document.body.classList.add("show");
  if ($intro) {
    introAnimation();

    gsap.fromTo(
      '[data-project-intro="headline"]',
      { opacity: 0, y: 50 },
      {
        opacity: 1,
        y: 0,
        duration: 1,
        scrollTrigger: {
          trigger: '[data-project-intro="root"]',
          start: "top 80%",
          toggleActions: "play none none none",
        },
      }
    );

    gsap.fromTo(
      '[data-project-intro="copy"]',
      { opacity: 0, y: 50 },
      {
        opacity: 1,
        y: 0,
        duration: 1,
        scrollTrigger: {
          trigger: '[data-project-intro="headline"]',
          start: "top 80%",
          toggleActions: "play none none none",
          once: true,
        },
      }
    );
  }

  if ($stage) {
    stageAnimation($stage);
  }
});

const $$inview = document.querySelectorAll("[data-inview]");

const ioFadeOptions = {
  threshold: 0.5,
  rootMargin: "0px 0px -10px 0px",
};

const ioFadeIn = new IntersectionObserver((entries) => {
  entries.forEach((entry) => {
    if (!entry.isIntersecting) {
      entry.target.classList.remove("inview");
    } else {
      entry.target.classList.add("inview");
    }
  });
}, ioFadeOptions);

$$inview.forEach((inview) => {
  ioFadeIn.observe(inview);
});

const magnets = document.querySelectorAll(".magnatic-cta");
const strength = 100;

magnets.forEach((magnet) => {
  magnet.addEventListener("mousemove", moveMagnet);
  magnet.addEventListener("mouseout", (event) => {
    anime({
      targets: event.currentTarget,
      duration: 1000,
      translateX: 0,
      translateY: 0,
    });
  });
});

function moveMagnet(event) {
  var magnetButton = event.currentTarget;
  var bounding = magnetButton.getBoundingClientRect();
  anime({
    targets: magnetButton,
    duration: 1000,
    easing: "easeOutQuart",
    translateX: {
      value: () => {
        return ((event.clientX - bounding.left) / magnetButton.offsetWidth - 0.5) * strength;
      },
    },
    translateY: {
      value: () => {
        return ((event.clientY - bounding.top) / magnetButton.offsetHeight - 0.5) * strength;
      },
    },
  });
}
