import "lazysizes";
import "./animation";
//import "./three";
import { ScrollCaptain } from "scroll-captain";

import Splitting from "splitting";

const faqItems = document.querySelectorAll(".faq__card");

faqItems.forEach((card) => {
  const closeCta = card.querySelector(".faq__card-close");

  card.addEventListener("click", (e) => {
    if (e.currentTarget.classList.contains("faq__card--open")) {
      e.currentTarget.classList.remove("faq__card--open");
    } else {
      faqItems.forEach((c) => {
        c.classList.remove("faq__card--open");
      });
      card.classList.add("faq__card--open");
    }
  });
});

const countUp = (element, endValue, duration) => {
  let startValue = 0;
  let startTime = null;

  const animation = (currentTime) => {
    if (!startTime) startTime = currentTime;
    const progress = Math.min((currentTime - startTime) / duration, 1);
    element.textContent = Math.floor(progress * (endValue - startValue) + startValue);
    if (progress < 1) {
      requestAnimationFrame(animation);
    }
  };

  requestAnimationFrame(animation);
};

const createObserver = () => {
  let observer = new IntersectionObserver((entries, observer) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        const target = entry.target;
        const endValue = parseInt(target.getAttribute("data-count"), 10);
        countUp(target, endValue, 2000);
        observer.unobserve(target);
      }
    });
  });

  document.querySelectorAll("[data-count]").forEach((element) => {
    observer.observe(element);
  });
};

// Initialisieren des Observers beim Laden der Seite
document.addEventListener("DOMContentLoaded", createObserver);

Splitting({
  /* target: String selector, Element, Array of Elements, or NodeList */
  target: "[data-splitting]",
  /* by: String of the plugin name */
  by: "chars",
  /* key: Optional String to prefix the CSS variables */
  key: null,
});

const $scrollCaptainProjects = document.querySelector('[data-scrollcaptain="projects"]');

if ($scrollCaptainProjects) {
  const projectsAnimation = new ScrollCaptain($scrollCaptainProjects, {
    initAttr: "data-scrollcaptain",
    top: "100%",
    triggerPosition: 0,
    animateImageWrapper: {
      targetSuffix: "image-wrapper",
      easing: "ease-out",
      onscroll: true,
      translateX: { 0: 100, 40: 0 },
    },
    onScroll: (scrolled) => {
      $scrollCaptainProjects.style.setProperty("--pos", Math.round(scrolled * 100) + "%");
    },
  });
}

const $scAboutIntro = document.querySelector('[data-scroll-about="root"]');
const $scAboutImage = document.querySelector('[data-scroll-about="about-image"]');

if ($scAboutIntro) {
  const aboutAnimation = new ScrollCaptain($scAboutIntro, {
    initAttr: "data-scroll-about",
    top: "-100%",
    bottom: "50%",
    triggerPosition: 0,
    animateAboutIntroText: {
      targetSuffix: "about-intro-text",
      easing: "ease-out",
      onscroll: true,
      translateY: { 0: 0, 100: 100 },
    },
    animateAboutImage: {
      targetSuffix: "about-image",
      easing: "ease-out",
      onscroll: true,
    },
    onScroll: (scrolled) => {
      $scAboutImage.style.setProperty("--pos", Math.round(scrolled * 100) * 2 + "%");
    },
  });
}

const $projectsIntro = document.querySelector(".projects__intro");

if ($projectsIntro) {
  const projectsIntro = new ScrollCaptain($projectsIntro, {
    initAttr: "data-project-intro",
    top: "20%",
    triggerPosition: 0,
    animateContact: {
      targetSuffix: "contact",
      easing: "ease-out",
      onscroll: true,
      translateX: { 0: 0, 100: 100 },
    },
    breakpoints: {
      1024: {
        animateContact: {
          translateX: { 0: 0, 100: 200 },
        },
      },
    },
  });
}

const $intro = document.querySelector('[data-intro="root"]');

document.addEventListener("introEnd", function (e) {
  if ($intro) {
    const projectsIntro = new ScrollCaptain($intro, {
      initAttr: "data-intro",
      devMode: true,
      bottom: "-100%",
      triggerPosition: 0,
      animateContact: {
        targetSuffix: "contact",
        easing: "ease-out",
        onscroll: true,
        scale: { 0: 1, 100: 1.4 },
        translateX: { 0: 0, 100: -100 },
      },
      breakpoints: {
        1024: {
          animateContact: {
            translateY: { 0: 0, 100: 200 },
            translateX: { 0: 0, 100: 0 },
            scale: { 0: 1, 100: 1.1 },
          },
        },
      },
    });
  }
});

const $about = document.querySelector('[data-about="root"]');
if ($about) {
  const w = $about.getBoundingClientRect().width;
  const d = w * 3;
  const move = d * -1;

  const aboutAnimation = new ScrollCaptain($about, {
    initAttr: "data-about",
    top: "75%",
    triggerPosition: 0,
    animateGhost: {
      targetSuffix: "ghost",
      easing: "ease-out",
      onscroll: true,
      translateX: { 0: 0, 100: (move / 2) * -1 },
    },
    animateGhostFirst: {
      targetSuffix: "ghost-first",
      easing: "ease-out",
      onscroll: true,
      translateX: { 0: 0, 100: move / 2 },
    },
    onScroll: (scrolled) => {
      $about.style.setProperty("--pos", Math.round(scrolled * 100) + "%");
    },
  });
}

const $contact = document.querySelector('[data-contact="root"]');
if ($contact) {
  const contactAnimation = new ScrollCaptain($contact, {
    // Basic options
    initAttr: "data-contact",
    top: "100%",
    triggerPosition: 0,
    // Animation options
    animateHeadline: {
      targetSuffix: "headline",
      easing: "ease-out",
      onscroll: true,
      translateY: { 0: -100, 80: 0 },
    },
    animateLinks: {
      targetSuffix: "links",
      easing: "ease-out",
      onscroll: true,
      translateY: { 0: 100, 80: 0 },
    },
    animateHearth: {
      targetSuffix: "hearth",
      easing: "ease-out",
      onscroll: true,
      scale: { 0: 0, 60: 1 },
    },
    breakpoints: {
      768: {
        animateHearth: {
          translateX: { 0: -100, 90: 0 },
          scale: { 0: 1, 60: 1 },
        },
      },
    },
  });

  const anchors = document.querySelectorAll('a[data-contact-link="root"]');

  if (anchors.length > 0) {
    anchors.forEach((anchor) => {
      anchor.addEventListener("click", function (e) {
        e.preventDefault();

        const targetID = this.getAttribute("href");

        if (targetID) {
          const targetElement = document.querySelector(targetID);

          if (targetElement) {
            targetElement.scrollIntoView({
              behavior: "smooth",
              block: "start",
            });
          } else {
            console.error(`Element with ID ${targetID} not found.`);
          }
        } else {
          console.error("Anchor element has no href attribute.");
        }
      });
    });
  } else {
    console.error('No anchor elements with data-contact-link="root" found.');
  }
}

const $navBtn = document.querySelector(".header__menu-text");

$navBtn.addEventListener("click", () => {
  $navBtn.classList.toggle("header__menu-text--open");
});
